//s is exposed globally
const trackAsync = (action) => {    
    s.tl(null, 'lnk_o', action);
};

const trackSync = () => {
    s.t();
}

const trackAction = (action) => {
    const { s } = window

    if (!s) return;

    const trackActionEnabled = splashKillSwitch && !splashKillSwitch.disableAdobeTracking;

    if (s && trackActionEnabled) {
        console.log('tracking action: ', action);

        const trackingVal = action.value;
        const urlSlug = window.location.pathname;
        const section = 'Marketing Splash';

        if (window.AnalyticsManager) {
            s.contextData = window.AnalyticsManager.contextData;
        }
                
        switch(action.type) {
            case 'pageLoad':
                s.pageName = trackingVal;
                s.contextData.urlSlug = urlSlug;
                s.contextData.section = section
                s.contextData['clickpath.previous_page'] = document.referrer;
                trackSync();
                break;
            case 'click':
                s.contextData.action = trackingVal;
                s.contextData.section = section;
                s.contextData.urlSlug = urlSlug;
                s.contextData.ctaButtonURL = action.ctaButtonURL;
                s.contextData.ctaButtonCopy = action.ctaButtonCopy;
                if (action.moduleLocation) {
                    s.contextData.moduleLocation = action.moduleLocation;
                }
                trackAsync(trackingVal);
                break;
            case 'signUpSuccess':
                s.contextData.action = trackingVal;
                s.contextData.section = section;
                s.contextData.urlSlug = urlSlug;
                s.contextData.ctaButtonURL = action.ctaButtonURL;
                s.contextData.ctaButtonCopy = action.ctaButtonCopy;
                s.contextData.moduleLocation = action.moduleLocation;
                trackAsync(trackingVal);
                break;
            case 'signUpFailure':
                s.contextData.action = trackingVal;
                s.contextData.section = section;
                s.contextData.urlSlug = urlSlug;
                s.contextData.ctaButtonURL = action.ctaButtonURL;
                s.contextData.ctaButtonCopy = action.ctaButtonCopy;
                s.contextData.moduleLocation = action.moduleLocation;
                trackAsync(trackingVal);
                break;
            default:
                return null;
        }
    } else {
        console.warn('No analytics suite available for action: ', action);
    }
};

export default trackAction;
