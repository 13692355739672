/**
 * @file uiUtils
 */

export const formatDuration = (duration) => {
    const durationDate = new Date(duration * 1000);

    const hours = durationDate.getUTCHours();
    const minutes = durationDate.getMinutes();
    const seconds = durationDate.getSeconds();

    let time = '';

    if (hours > 0) {
        time += `${hours}:`;
    }

    time += minutes < 10 ? `0${minutes}:` : `${minutes}:`;

    time += seconds < 10 ? `0${seconds}` : `${seconds}`;

    return time;
};