import sessionUtil from 'bam-browser-sdk-sdkSession-util';
import SDK from 'bam-browser-sdk';

export async function initializeSdk(cb) {
    const { server_path } = window;

    sessionUtil.setBootstrapConfiguration(server_path.sdk);
    try {
        const session = await sessionUtil.getSdkSession();
        const info = await session.getSessionInfo();
        const UserActivity = {
            GlimpseEvents: SDK.UserActivity.Glimpse.GlimpseEvents,
            UserActivityApi: SDK.UserActivity.UserActivityApi
        };

        if (typeof(cb) === 'function') {
            cb({
                UserActivity,
                sessionInfo: info
            });
        }

    } catch (err) {
        console.error(err);
    }

}
