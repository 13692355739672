import AOS from 'aos';

const initAnimations = () => {
    const belowthefold = document.getElementById('below-the-fold');

    if (belowthefold) {

        let delay = 0;

        const arrow = document.getElementById('arrow')

        /*Show nav bar and stop arrow bounce once user has scrolled*/

        const toggleArrowBounce = () => {
            if (window.pageYOffset > 0) {
                arrow.classList.remove('bounce')
            }
        }

        const toggleNavBar = () => {
            const nav = document.getElementById('nav')
            const main = document.getElementById('main')
            window.pageYOffset >= main.clientHeight * .75 ? nav.classList.add('show') : nav.classList.remove('show')
        }

        window.addEventListener('scroll', function () {
            toggleNavBar()
            toggleArrowBounce()
        });

        /*Scroll Animations*/

        const headers = belowthefold.querySelectorAll('.headers')
        
        if (headers) {
            headers.forEach(function (i) {
                i.setAttribute('data-aos', 'fade-up');
                i.setAttribute('data--duration', '1000');
                i.setAttribute('data-aos-delay', '100');
            })
        }

        const row3s = belowthefold.querySelectorAll('.row3')

        if (row3s) {
            row3s.forEach(function (row) {
                const gridItems = row.querySelectorAll('.grid_item');
                gridItems.forEach(function (item, i) {
                    delay = i * 100
                    item.setAttribute('data-aos', 'fade-up');
                    item.setAttribute('data-aos-duration', '750');
                    item.setAttribute('data-aos-delay', delay);
                    item.setAttribute('data-aos-anchor', '#below-the-fold');
                })
            })
        }

        const grid3s = belowthefold.querySelectorAll('.grid3')

        if (grid3s) {
            grid3s.forEach(function (grid) {
                const gridItems = grid.querySelectorAll('.grid_item');
                gridItems.forEach(function (item, i) {
                    delay = 1000 + i * 50
                    item.setAttribute('data-aos', 'fade-up');
                    item.setAttribute('data-aos-duration', '750');
                    item.setAttribute('data-aos-delay', delay);
                    item.setAttribute('data-aos-anchor', '#exclusive');
                    item.setAttribute('data-aos-placement', 'top-bottom')
                })
            })
        }

        const foregrounds = belowthefold.querySelectorAll('.foreground-image')

        if (foregrounds) {
            foregrounds.forEach(function (foreground) {
                foreground.setAttribute('data-aos', 'fade');
                foreground.setAttribute('data-aos-duration', '1000');
                foreground.setAttribute('data-aos-delay', '500');
            })
        }

        const multilayers = belowthefold.querySelectorAll('.multilayer');

        if (multilayers) {
            multilayers.forEach(function (multilayer) {
                const foreground = multilayer.querySelector('.foreground-image');
                foreground.setAttribute('data-aos', 'slide-up');
                foreground.setAttribute('data-aos-duration', '2500');
                foreground.setAttribute('data-aos-delay', '750');
                foreground.setAttribute('data-aos-placement', 'bottom-center')
                const background = multilayer.querySelector('.background-image');
                background.setAttribute('data-aos', 'fade-up');
                background.setAttribute('data-aos-duration', '1000');
                background.setAttribute('data-aos-delay', '250');
            })
        }

        const countdown = document.getElementById('countdown-bottom');

        if (countdown) {
            countdown.setAttribute('data-aos', 'fade-up');
            countdown.setAttribute('data-aos-duration', '750');
            countdown.setAttribute('data-aos-delay', '500');
        }

        if (countdown && headers) {
            const countdownHeader = headers[headers.length - 1];
    
            countdownHeader.setAttribute('data-aos', 'fade-up');
            countdownHeader.setAttribute('data-aos-duration', '1000');
            countdownHeader.setAttribute('data-aos-delay', '0');
        }

        const cta = belowthefold.querySelector('.oneIdGroup');

        if (cta) {
            cta.setAttribute('data-aos', 'fade-up');
            cta.setAttribute('data-aos-duration', '1000');
            cta.setAttribute('data-aos-delay', '700');
        }

        AOS.init({once: true});
    }
}
export default initAnimations;
