/**
 *
 * @file entry
 * @desc The renderers entry point
 *
 */

//it's worth noting in our example that the ui projects contentControlsView.js
//looks for a renderer in 'ui.renderers.contentControls' which is why we
//are 'renaming' the renderer here'. If 'ui.renderers.contentControls' is not present
//it uses a default renderer.
import { contentControlsRenderer as contentControls } from './contentControlsRenderer';

/**
 *
 * @access public
 * @namespace renderers
 * @memberof renderers
 * @desc renderers entry point
 *
 */
export const renderers = {

    /**
     *
     * @type renderers.contentControls
     * @memberof renderers
     * @desc The renderers.contentControl namespace
     *
     */
    contentControls

};
