import { pick } from 'lodash';
import { deepGet } from 'bam-deep-get';

const dataLayerKeys = [
    'cid',
    'countryCode',
    'countryGroup',
    'mid',
    'swid',
    'deviceAdvertisingId',
    'dssAccountId',
    'dssDeviceId',
    'dssProfileId',
    'dssSessionId',
    'logInStatus',
    'pageName',
    'pageURL',
    'userProductName',
    'userProductSku',
    'userSubscriptionState',
    'userVisitorState'
];

function updateDataLayer() {
    const { contextData, sessionInfo } = window.AnalyticsManager;
    window.dssDataLayer = pick({
        ...contextData,
        logInStatus: contextData.loggedIn,
        pageName: document.body.getAttribute('data-analytics-pagename'),
        pageURL: window.location.href,
        countryCode: sessionInfo.location.countryCode,
        countryGroup: deepGet(window, 'dssSplashConfig.regionConfig.group', '')
    }, dataLayerKeys);

    document.body.dispatchEvent(new CustomEvent('dynamicPageIsDisplayed', {
        bubbles: true,
        cancelable: true
    }));


}

function loadScript() {
    const launchScriptUrl = deepGet(window, 'dssSplashConfig.adobeLaunchUrl');

    if (launchScriptUrl) {
        const script = document.createElement('script');
        script.src = launchScriptUrl;
        script.onload = () => {
            updateDataLayer();
        };

        document.body.appendChild(script);
    }
}

/**
 * Only load Adobe Launch if not in the EU or once accepted Cookie Consent
 */
export function initAdobeLaunch() {
    loadScript();
}
