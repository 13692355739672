import disneyOneId from './oneid';

function init() {
    const oneIdButtons = document.querySelectorAll('.oneidButton');

    console.log('Initializing One Id...');
    const oneId = new disneyOneId();
    console.log('OneId Loaded: ', oneId)

    for (let i = 0; i < oneIdButtons.length; i++) {
        if (oneId && oneIdButtons) {
            oneIdButtons[i].disabled = false;
            oneIdButtons[i].addEventListener('click', function(event) {
                oneId.start(oneIdButtons[i]);
            })
        } else {
            console.warn("No valid OneId Instance found");
        }
    }
}   

export default { init };
