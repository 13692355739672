import '@babel/polyfill';
import 'pepjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import smoothscroll from 'smoothscroll-polyfill';

import trackAction from './trackAction';
import OneIdButton from './oneidbutton';
import getCountdownClock from './countdown';
import initAnimations from './animation';

import { MediaPlayer } from 'btm-browser-media-platform-player/dist/default/player/mediaPlayer';
import MediaPlatformUI from 'btm-browser-media-platform-player-ui';
import { getExtendedPlayerConfig as getExtendedPlayerConfigConviva } from 'btm-browser-media-platform-ext-conviva/dist/default/getExtendedPlayerConfig';
import { MediaResource } from 'btm-browser-media-platform-player/dist/default/player/mediaResource';
import { PlayerEvents } from 'btm-browser-media-platform-common/dist/default/common/constants/communication/events/playerEvents';
import { buildEnvironmentProps } from 'btm-browser-media-platform-common/dist/default/common/utils/env/device';

import { renderers } from './renderers';
import uuid from 'uuid';

import { initializeSdk } from './utils/sdk.js';
import AnalyticsManager from './analytics/analyticsManager';
import { initAdobeLaunch } from './analytics/adobeLaunch';
import { customEventPolyfill } from './utils/customEventPolyfill';

customEventPolyfill();
const videoContainer = document.getElementById('video');
const killswitchPageConfig = window.splashKillSwitch;
const isAndroid = buildEnvironmentProps().is.android;
const belowthefold = document.getElementById('below-the-fold');
const main = document.getElementById('main');

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg = window) {
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

if (document.body.classList.contains('404') || document.body.classList.contains('unavailable')) {
    const footer = document.getElementById('footer');
    main.classList.remove('has-below-the-fold');
    main.classList.remove('cms-driven');
    footer.classList.remove('has-below-the-fold');
}

const mainLinks = main.querySelectorAll('a')

mainLinks.forEach(function (link) {
    link.addEventListener('click', function (e) {
        trackAction({
            type: 'click',
            value: 'Marketing Splash : Hero Click',
            ctaButtonURL: link.href,
            ctaButtonCopy: link.innerText
        })
    })
})

const footer = document.querySelector('#footer')
const footerLinks = footer.querySelectorAll('a')

footerLinks.forEach(function(link) {
    link.addEventListener('click', function(e) {
        trackAction({
            type: 'click',
            value: 'Marketing Splash : Footer Click',
            ctaButtonURL: link.href,
            ctaButtonCopy: link.innerText,
            moduleLocation: '[footer] [null] [null]'
        })
    })
})

const nav = document.querySelector('.nav')

if (nav) {
    const navLinks = nav.querySelectorAll('a')

    navLinks.forEach(function (link) {
        link.addEventListener('click', function (e) {
            trackAction({
                type: 'click',
                value: 'Marketing Splash : Navigation Click',
                ctaButtonURL: link.href,
                ctaButtonCopy: link.innerText
            })
        })
    })
}

if (window && main.classList.contains('has-below-the-fold')) {
    initAnimations();

    smoothscroll.polyfill();

    const hint = document.querySelector('#hint')

    hint.addEventListener('click', function (e) {
       belowthefold.scrollIntoView({
            behavior: 'smooth'
        });

        trackAction({
            type: 'click',
            value: 'Marketing Splash : Down Arrow Click',
            ctaButtonURL: '/',
            ctaButtonCopy: null,
            moduleLocation: '[hero] [null] [null]'
        })
    })

    const h2s = document.body.querySelectorAll('h2')
    const favorites = h2s[2]
    favorites.classList.add('favorites')
}

const euLogin = document.getElementById('eu-login');

if (euLogin) {
    const euLinks = euLogin.querySelectorAll('a');
    euLinks.forEach(function (link) {
        link.addEventListener('click', function (e) {
            trackAction({
            type: 'click',
                value: 'Marketing Splash : EU Login Click',
                ctaButtonURL: euLogin.href,
                ctaButtonCopy: euLogin.innerText
            })
        })
    })
}

const getViewerIdFromLocalStorage = () => {
    try{
        return window.localStorage.getItem('viewerId');
    } catch(e) {
        //local storage failed for some reason, return something so we can continue to set a viewerId
        return undefined;
    }
};

/**
 *
 * @param {String} viewerId - UUID generated for a user
 */
const saveViewerIdToLocalStorage = (viewerId) => {
    try{
        window.localStorage.setItem('viewerId', viewerId);
    } catch(e) {
        //Save couldn't happen because of an error.
    }
};

/**
 * Generate a new UUID for a user and save it to the localStorage
 * This value is kept around to identify a specific viewer across sessions (in a browser)
 */
const generateAndSaveUUID = () => {
    const newId = uuid.v4();
    saveViewerIdToLocalStorage(newId);
    return newId;
}

if (!window.disneyIdService) {
    OneIdButton.init();
}


/**
 * Graceful Handling of Countdown
 */
const countdownComplete = () => {
    document.querySelectorAll('[data-countdown-complete]').forEach(el => {
        const newHeading = el.getAttribute('data-countdown-complete');
        el.innerHTML = newHeading;
    });
}

/**
 * Countdown Time Interval
 * Check for data-datetime and data-countdown before continuing
 */
const hasCountdown = document.querySelectorAll('[data-countdown]');
if (main && main.dataset.datetime && hasCountdown.length) {

    const days = document.querySelectorAll('.days');
    const hours = document.querySelectorAll('.hours');
    const minutes = document.querySelectorAll('.minutes');
    const seconds = document.querySelectorAll('.seconds');

    dayjs.extend(utc);

    const dateTimeString = main.dataset.datetime;

    let beforeLaunch = dayjs().utc().isBefore(dayjs(dateTimeString));
    let timeobject;

    const countdownInterval = setInterval(function() {

        // Subtract 1 second from comparison so the text transitions exactly at 0
        beforeLaunch = dayjs().utc().isBefore(dayjs(dateTimeString).subtract(1, 'second'));

        if (beforeLaunch) {
            timeobject = getCountdownClock(dateTimeString);
        } else {
            timeobject = {
                d: '0',
                h: '00',
                m: '00',
                s: '00'
            };
            countdownComplete();
            clearInterval(countdownInterval);
        }

        days.forEach(function(i) {
            i.innerHTML = timeobject.d;
        });
        hours.forEach(function (i) {
            i.innerHTML = timeobject.h;
        });
        minutes.forEach(function (i) {
            i.innerHTML = timeobject.m;
        });
        seconds.forEach(function (i) {
            i.innerHTML = timeobject.s;
        });

    }, 1000);

}


/**
 * Video Player
 */
const convivaEnabled = !killswitchPageConfig.disableConviva;
const videoPlayerEnabled = !killswitchPageConfig.disableVideoPlayer && videoContainer;

if ( videoPlayerEnabled ) {

    const {
        media,
        convivaConfig,
        convivaData
    } = killswitchPageConfig.videoPlayerConfig;

    if (isAndroid && killswitchPageConfig) {
        //android overrides have mercy
        killswitchPageConfig.videoPlayerConfig.media.mediaResource = killswitchPageConfig.videoPlayerConfig.android.mediaResource;
        killswitchPageConfig.videoPlayerConfig.convivaData = Object.assign({}, killswitchPageConfig.videoPlayerConfig.convivaData, killswitchPageConfig.videoPlayerConfig.android.convivaData);
    }

    const getConvivaAppData = (convivaConfig, viewerId, convivaSDK) => {

        const { appName,
            playerType,
            customerKey,
            customerKeyTest,
            testNetwork,
            enableTouchstone,
            gatewayUrlTest,
            gatewayUrl } = convivaConfig;

        //If we're targeting the testNetwork, then modify some of the keys.
        //If we're not then leave it empty.
        const testParams =  testNetwork ?
        { //Test specific App Data
            customerKey: customerKeyTest,
            gatewayUrl: enableTouchstone ? gatewayUrlTest : gatewayUrl,
            debugLoggingEnabled: false
        } :
        {};

        //Merge the conviva App Data, test will replace Prod if it's set.
        return Object.assign({},
            { //Prod Conviva App Data
                ConvivaSDK: convivaSDK,
                applicationName: appName,
                playerType: playerType,
                playerVersion: '1.0.0.alpha/5.2.0',
                customerKey: customerKey,
                viewerId: viewerId,
                gatewayUrl: gatewayUrl,
                debugLoggingEnabled: false
            },
            testParams);

    };

    const {
        MediaPlayerUI,
        getExtendedPlayerConfig: getExtendedPlayerConfigUI,
        View: {
            DefaultView
        }
    } = MediaPlatformUI.Player.UI;


    const toPlayerUI = MediaPlayerUI(
        videoContainer,
        DefaultView
     );

    const mediaPlayerConfig = {
        logging: {
            logLevel: 50
        }
    };

    let extensions = [
        getExtendedPlayerConfigUI({ renderers, controlsOverlay: { decay: 4 }})
    ]

    // Add the Plugin to the player extensions if we're enabling it.
    if(convivaEnabled) {
        let viewerId = getViewerIdFromLocalStorage();
        //If we didn't have one saved, generate a new one, save it and use that.
        if( !viewerId ) {
            viewerId = generateAndSaveUUID();
        }

        extensions.push(getExtendedPlayerConfigConviva(getConvivaAppData(convivaConfig, viewerId)));
    }

    const mediaPlayer = MediaPlayer(
        toPlayerUI,
        mediaPlayerConfig,
        ...extensions
        );

        //set poster via api - this is a work around
        mediaPlayer.ui.overlays.poster.image = media.poster;

        //add fguid value to convivaData - this is uuid set per playback session, not per user as viewer id above is handled
        convivaData.fguid = uuid.v4();

        const mediaResource = MediaResource(media.mediaResource, [], { tracking: { conviva: convivaData } }, { autoPlay: { enabled: false, muteAndRetryOnFail: false } });

        mediaPlayer.mediaResource = mediaResource;

        mediaPlayer.ui.overlays.poster.show();

        mediaPlayer.on(PlayerEvents.MEDIA_READY, () => {
            mediaPlayer.ui.overlays.clickToPlay.show();
        });

        //start media
        mediaPlayer.on(PlayerEvents.PLAYBACK.MEDIA_STARTED, () => {
           trackAction({ type: 'video', value: 'Video Player : Video Start'});
        });

        //finish media
        mediaPlayer.on(PlayerEvents.PLAYBACK.MEDIA_COMPLETED, () => {
            //mediaPlayer.ui.overlays.poster.image = media.poster;
            mediaPlayer.ui.overlays.poster.show();
            mediaPlayer.ui.overlays.clickToPlay.show();

            trackAction({type: 'video', value: 'Video Player : Video 100 Percent Complete'});
        });

    } //end videoPlayerEnabled


    initializeSdk(sdkPayload => {
        window.AnalyticsManager = new AnalyticsManager(sdkPayload);
        initAdobeLaunch();
        trackAction({ type: 'pageLoad', value: 'Marketing Splash'});
    });
