import { getDevice } from 'dss-device';
import { deepGet } from 'bam-deep-get';
import * as Cookies from 'es-cookie';

/**
 * Class listens for trackable events and fires custom event to allow for easy use by integrated
 *  Analytics platforms
 */
export default class AnalyticsManager {
    constructor(skdPayload) {
        const { UserActivity } = skdPayload;
        this.triggerEvent = this.triggerEvent.bind(this);
        this.deviceData = {};
        this.userData = {};
        //this.userActivityApi = new UserActivity.UserActivityApi;
        //this.glimpseEvents = UserActivity.GlimpseEvents;
        this.glimpseEvents = null;
        this.initContextData(skdPayload.sessionInfo);
        this.bindPageLoad();
        this.bindElementEvents();
    }

    /**
     * Getter returns the gloabl context data
     * @returns {object}
     */
    get contextData() {
        return {
            ...this.deviceData,
            ...this.userData,
            ...this.updateDynamicData()
        };
    }

    get sessionInfo() {
        return this._sessionInfo;
    }

    /**
     * Initialize the static context data
     * @param {object} sessionInfo - The current session info from the bam-browser-sdk-sdkSession-util module
     */
    initContextData(sessionInfo) {
        const device = getDevice();
        const { s, s_account, navigator, server_path } = window;
        const { browser, browserVersion, formFactor, platform, platformVersion, userAgent } = device;
        const deviceStr = (formFactor === 'phone') || (formFactor === 'tablet') ? 'Mobile Web' : 'Web';

        this._sessionInfo = sessionInfo;

        this.deviceData = {
            browserType: browser,
            browserVersion,
            connectionType: deepGet(navigator.connection, 'type', 'unknown'),
            device: deviceStr,
            deviceType: formFactor,
            mid: (visitor) ? visitor.getMarketingCloudVisitorID() : '',
            osName: platform,
            osVersion: platformVersion,
            platform: 'Web',
            platformType: 'Web',
            userAgent
        };

        this.userData = {
            adobeRsid: s_account,
            adobeRsidName: server_path.adobe.rsidname,
            adobeSdkVersion: s.version,
            deviceLanguage: navigator.language.split('-')[0],
            deviceLocale: navigator.language,
            dssDeviceId: sessionInfo.device.id,
            dssSessionId: sessionInfo.id,
            userVisitorState: s.getNewRepeat()
        };
    }

    /**
     * Some analyics data changes per event tracked.
     */
    updateDynamicData() {
        const { s } = window;
        const { screenOrientation } = getDevice();
        const cid = s.Util.getQueryParam('cid');

        return {
            cid,
            loggedIn: false,
            previousUrl: document.referrer,
            consentStatus: !!Cookies.get('dplusCookieConsent'),
            screenOrientation,
            timestamp: new Date().toString()
        };
    }

    /**
     * Find all elements that are to be tracked and listen for the specified event. Once that event is triggered
     * fire a custom event.
     */
    bindElementEvents() {
        document.querySelectorAll('[data-analytics]').forEach(el => {
            const captureEvent = el.getAttribute('data-analytics') || 'click';

            el.addEventListener(captureEvent, this.triggerEvent);
        });
    }

    /**
     * Listen for the page load event
     */
    bindPageLoad() {
        document.body.addEventListener('load', this.triggerEvent);
    }

    /**
     * Fire a custom event with the string syntax of 'analtyics_$type'
     * @example analytics_click, analytics_load, analytics_change, etc
     * @param {Event} e - Event Object
     */
    triggerEvent(e) {
        let customEvent;

        //IE 11 has CustomEvent Obj but doesn't like the constructor for IE reasons
        try {
            customEvent = new CustomEvent(`analytics_${e.type}`, {
                bubbles: true
            });
        } catch (err) {
            customEvent = document.createEvent('CustomEvent');
            customEvent.initCustomEvent(`analytics_${e.type}`, true, false, {});
        }

        e.currentTarget.dispatchEvent(customEvent);
    }

    /** Fire the page load even */
    firePageLoad() {
        this.triggerEvent({ type: 'load', currentTarget: document.body });
        //this.fireGlimpseEvent('viewLoaded', { pagename: 'D+ Marketing Landing Page' });
    }

    /** Handle Glimpse Tracking Events **/
    fireGlimpseEvent(glimpseEvent, attributes) {
        const {
            appLaunched,
            viewLoaded
        } = this.glimpseEvents;

        const glimpseTimeStamp = new Date();
        glimpseTimeStamp.toISOString();

        const glimpseTrackingParameters = {
            appName: 'Disney+ MLP',
            appVersion: '0.0.0',
            timeStamp: glimpseTimeStamp
        };

        let event;
        let additionalParameters;

        switch (glimpseEvent) {
            case 'appLaunched':
                event = appLaunched;
                break;
            case 'viewLoaded':
                event = viewLoaded;
                additionalParameters = {
                    loadType: 'Full',
                    pageName: attributes.pagename
                };
                break;
        }

        this.userActivityApi.setTrackingParameters(glimpseTrackingParameters);
        this.userActivityApi.trackEvent(event, additionalParameters);

    }
}
