import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const getMilliseconds = (launchDateTimeString) => { //launchDateTimeString is ISO 8601 string using UTC from locale config
    const now = dayjs().utc();
    const release = dayjs(launchDateTimeString);
    const millisecondsUntilRelease = release.diff(now);

    return millisecondsUntilRelease;
};

const pad = (n) => {
    let padded = '' + n;

    if (padded.length < 2) {
        padded = '0' + padded;
    }

    return padded;
};

const getTimeObject = (ms) => {
    let d, h, m, s;

    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;

    return { d, h: pad(h), m: pad(m), s: pad(s) };
};

const getCountdownClock = (launchDateTimeString) => {
    const ms = getMilliseconds(launchDateTimeString);
    const timeObject = getTimeObject(ms);

    return timeObject;
};

export default getCountdownClock;
