import trackAction from './trackAction';

const isStagingEnvironment = () => {
    const element = document.querySelector('meta[name~="env"]');
    return element && (element.getAttribute("content") !== 'production');
}

const getResponder = () => {
    return window.location.hostname.indexOf('preview-qa') > -1 ? window.server_path.responder_qa : window.server_path.responder;
}

const getCssOverride = () => {
    return isStagingEnvironment() ? 'https://dmgz-splash-static.bamgrid.com/splash/one-id-css/qa/oneid-web-override-goofy.css' : 'https://dmgz-splash-static.bamgrid.com/splash/one-id-css/prod/oneid-web-override.css';
}

const getLocale = () => {
    const element = document.querySelector('meta[name~="locale"]');
    return element && element.getAttribute("content");
}
export default class OneId {

    constructor() {
        this.campaignID = 'PreRelease'

        this.trackingData = {
            ctaButtonURL: null,
            ctaButtonCopy: null,
            moduleLocation: null
        }

        this.config = {
            clientId: 'DTCI-DOMINGUEZPRERELEASE.WEB',
            responderPage: getResponder(),
            cssOverride: getCssOverride(),
            debug: true,
            langPref: getLocale()
        };

        window.disneyIdService = window.DisneyID.get(this.config);
        this.did = window.disneyIdService;

        this.did.init().then(() => {
            this.inititializeListeners();
        })
    }

    inititializeListeners() {
        const did = this.did;
        const trackingData = this.trackingData;
        //OneId listeners for analytics tracking
        did.on('init', (e) => {
            console.log('client init');
        });

        did.on('opt-in', (e) => {
            console.log('opt-in event');
            trackAction({
                type: 'signUpSuccess',
                value: 'Marketing Splash : Sign Me Up Success',
                ctaButtonCopy: trackingData.ctaButtonCopy,
                ctaButtonURL: trackingData.ctaButtonURL,
                moduleLocation: trackingData.moduleLocation
            });
        });

        did.on('error', (e) => {
            console.log('oneid error: ', e);
            trackAction({
                type: 'signUpFailure',
                value: 'Marketing Splash : Sign Me Up Failure',
                ctaButtonCopy: trackingData.ctaButtonCopy,
                ctaButtonURL: trackingData.ctaButtonURL,
                moduleLocation: trackingData.moduleLocation
            });
        });
    }

    //"Keep Me Updated" Button Click
    start(target) {
        const did = this.did;
        const {section, analyticsSection, index} = target.dataset;

        //Store tracking values with clicked target data in class variable
        this.trackingData.ctaButtonCopy = target.innerText;
        this.trackingData.ctaButtonURL = target.href || '/';
        this.trackingData.moduleLocation = `[${section.toLowerCase()}] [${analyticsSection}] [${index}]`;

        //Initial click action tracking
        trackAction({
            type: 'click',
            value: `Marketing Splash : ${section} Click`,
            ctaButtonCopy: this.trackingData.ctaButtonCopy,
            ctaButtonURL: this.trackingData.ctaButtonURL,
            moduleLocation: this.trackingData.moduleLocation
        });

        //Open the LightBox
        did.launchNewsletters(this.campaignID)
            .then((acct) => {
                console.log('Newsletter Account: ', acct);
            })
            .catch((err) => {
                console.log('Error Launching Newsletter: ', err);
            });

    }

}
