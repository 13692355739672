/**
 *
 * @file contentDontrolsRenderer
 *
 */
import * as MediaPlatformUI from 'btm-browser-media-platform-player-ui';
import { h } from 'VDOMComponent'; // eslint-disable-line no-unused-vars
import * as CustomControls from './../content/entry';

const {
    TimeControl
} = CustomControls;

const {
    PlayControl,
    PauseControl,
    VolumeControl,
    MuteControl,
    ProgressBarControl,
    QuickRewindControl,
    QuickFastForwardControl,
    FullscreenControl,
    ExitFullscreenControl,
    //OverflowControl,
    WindowFlyoutControl
} = MediaPlatformUI.Player.UI.Controls.Content;

/**
 *
 * @access private
 * @function contentControlsRenderer
 * @param {Object} props Properties passed down to the Renderer
 * @returns {VNode} VDOM rendering of the Component.
 *
 */
export const contentControlsRenderer = ({ visible, muted }) => {

    return visible ? (
        <div className="controls controls__content">

            <div className="controls__header">

                <div className="display-flex spacer--horizontal">&nbsp;</div>
                <WindowFlyoutControl />
            </div>

            <div className="display-flex spacer--vertical">
                &nbsp;
            </div>

            <div className="controls__footer display-flex">

                <div className="controls__footer__wrapper display-flex">

                    <div className="controls__left">
                        <TimeControl />
                    </div>

                    <div className="controls__center">
                        <QuickRewindControl />
                        <PlayControl />
                        <PauseControl />
                        <QuickFastForwardControl />
                    </div>

                    <div className="controls__right">
                        <div className={`audio-control control-icon-btn muted-${muted}`}>
                            <div className="audio-control__mute-container control-content">
                                <MuteControl />
                            </div>
                            <div className="audio-control__volume-container popup-content">
                                <VolumeControl />
                            </div>
                        </div>
                        <FullscreenControl />
                        <ExitFullscreenControl />
                    </div>

                </div>

                <div className="progress-bar">
                    <ProgressBarControl />
                </div>

            </div>

        </div>
    ) : null;

};
