import { connect } from 'VDOMRedux';
import { Map } from 'immutable';

import { TimeControlComponent } from './../components/timeControlComponent';

export const mapStateToProps = (state = Map()) => {

    const ready = !state.getIn(['player', 'extensions', 'playback', 'notready']);
    const currentTime = state.getIn(['player', 'extensions', 'timecode', 'currentTime']);
    const proposedCurrentTime = state.getIn(['ui', 'controls', 'progressBar', 'proposedCurrentTime']);
    const proposingCurrentTime = proposedCurrentTime !== undefined;

    const durationFromState = state.getIn(['player', 'extensions', 'timecode', 'duration'], 0);
    const seekableRange = state.getIn(['player', 'extensions', 'timecode', 'seekableRange']) || Map();
    const visible = ready;
    
    const duration = seekableRange.get('duration', durationFromState);

    return {
        currentTime: proposingCurrentTime ? proposedCurrentTime : currentTime,
        duration,
        visible
    };

};

export const TimeControl = connect(
    mapStateToProps
)(TimeControlComponent);