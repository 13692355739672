// eslint-disable-next-line consistent-return
const customEventPolyfill = function customEventPolyfill() {

    if (typeof window.CustomEvent === 'function') { return false; }

    // eslint-disable-next-line require-jsdoc-except/require-jsdoc
    function CustomEvent(event, params) {
        // eslint-disable-next-line no-param-reassign
        params = params || { bubbles: false, cancelable: false, detail: null };
        var evt = document.createEvent('CustomEvent');

        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

        return evt;
    }

    window.CustomEvent = CustomEvent;
};

export {
    customEventPolyfill
};
