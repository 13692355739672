/**
 *
 * @file timeControlComponent
 *
 */

import { h } from 'VDOMComponent'; // eslint-disable-line no-unused-vars
import { Common } from 'btm-browser-media-platform-common';
import { PropTypes } from 'propTypes';
import { formatDuration } from '../utils/utils';

const DEFAULT_TIMECODE_STRING = '00:00';

export const TimeControlComponent = ({ visible, currentTime, duration, hideScores }) => {

    const currentDisplayTime = currentTime > 0
        ? formatDuration(currentTime)
        : DEFAULT_TIMECODE_STRING;

    const durationDisplay = !hideScores && duration > 0
        ? formatDuration(duration)
        : DEFAULT_TIMECODE_STRING;

    return visible ? (
        <div className="time-display-label">{currentDisplayTime}</div>
    ) : null;

};

TimeControlComponent.propTypes = {
    visible: PropTypes.bool,
    currentTime: PropTypes.number,
    duration: PropTypes.number,
    hideScores: PropTypes.bool
};

TimeControlComponent.defaultProps = {
    visible: true,
    currentTime: 0,
    duration: 0,
    hideScores: false
};